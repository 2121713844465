@use './index.scss' as *;
// Tailwind Style
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTucHtA.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5Z1xlFQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122,
    U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  overflow: hidden;
}
html {
  overflow: auto;
  padding-right: 0;
  overflow-x: hidden;
}
.btn-link {
  font-size: $hydra-text-xs;
  line-height: 1.2;
  color: $primary100;
  font-weight: $hydra-font-medium;
  transition: $hydra-transition-speed-3 all;
  &:disabled {
    color: $gray500;
    border-color: $gray500;
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    color: $primaryMedium;
  }
}
.btn-outline {
  font-size: $hydra-text-xs;
  line-height: 1.2;
  min-width: 4.4375rem;
  border: $hydra-dim-01 solid $primary100;
  color: $primary100;
  font-weight: $hydra-font-medium;
  transition: $hydra-transition-speed-3 all;
  border-radius: $hydra-radius-26;
  padding: $hydra-spacing-03 $hydra-dim-12;
  @extend %flex-a-item-center;
  min-height: 1.875rem;
  &.-type-2 {
    border: 1.5px solid $primary100;
    border-radius: $hydra-radius-6;
    font-weight: $hydra-font-semibold;
    font-size: $hydra-text-xs;
    line-height: 1.5;
    color: $primary100;
  }
  &:disabled {
    color: $white;
    border-color: $primary500;
    background-color: $primary500;
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    color: $primaryMedium;
  }
  svg {
    margin-right: $hydra-spacing-06;
    width: $hydra-dim-14;
    height: $hydra-dim-14;
  }
}
.btn-primary-1 {
  font-size: $hydra-text-xs;
  line-height: 1.2;
  min-width: 4.4375rem;
  border: $hydra-dim-01 solid $primary100;
  color: $white;
  font-weight: $hydra-font-medium;
  transition: $hydra-transition-speed-3 all;
  border-radius: $hydra-radius-26;
  padding: $hydra-spacing-03 $hydra-dim-12;
  @extend %flex-a-item-center;
  min-height: 1.875rem;
  background-color: $primary100;
  &:disabled {
    color: $gray500;
    border-color: $gray500;
    opacity: 0.4;
    cursor: not-allowed;
  }
  &:hover:not(:disabled) {
    color: $white;
  }
  svg {
    margin-right: $hydra-spacing-06;
    width: $hydra-dim-14;
    height: $hydra-dim-14;
  }
}
.btn-icon {
  transition: $hydra-transition-speed-3 all;
  position: relative;
  top: -$hydra-dim-01;
}
@layer base {
  .btn-primary {
    @apply h-11 rounded-full border border-primary500 bg-white px-6 text-sm font-medium text-primary100 hover:bg-primary500  hover:text-white focus:outline-none disabled:opacity-40 2xl:h-10 2xl:px-6;
  }
  .btn-primary-outline {
    @apply flex h-11 items-center rounded-full border border-white px-6 text-xs font-medium text-white hover:border-primary hover:bg-primary hover:text-white;
  }
  .btn-secondary-outline {
    @apply flex h-7.5 items-center rounded-full border border-primary px-3.5 text-xs font-medium text-primary hover:bg-primary hover:text-white;
  }
  .flexCenter {
    @apply flex items-center;
  }
  .delay01 {
    animation-delay: 0.1s;
  }
  .delay02 {
    animation-delay: 0.2s;
  }
  .delay03 {
    animation-delay: 0.3s;
  }
  .delay04 {
    animation-delay: 0.4s;
  }
  .delay05 {
    animation-delay: 0.5s;
  }
  .delay06 {
    animation-delay: 0.6s;
  }
  .delay07 {
    animation-delay: 0.7s;
  }
  .delay08 {
    animation-delay: 0.8s;
  }
  .delay09 {
    animation-delay: 0.9s;
  }
  .delay10 {
    animation-delay: 1s;
  }
  .formSelector {
    @apply mr-4;
    max-width: 80px;
    min-width: 80px;
    [class*='-control'] {
      @apply h-12 w-full rounded-lg border border-solid border-gray-dark4;
      div {
        @apply flex h-12 items-center py-3  pr-0 text-sm font-medium leading-5.5;
        div {
          @apply h-auto py-0 leading-none;
        }
        & + div {
          @apply pr-2;
        }
      }
    }
    [data-value] {
      display: none !important;
    }
    [class*='-ValueContainer'] {
      @apply h-12 py-3  pr-0 text-sm font-medium leading-5.5 text-gray-50;
    }
    [class*='-Input'] {
      @apply m-0 hidden h-12 p-0;
      grid-area: unset;
    }
    [class*='-indicatorSeparator'] {
      @apply hidden;
    }
    [class*='-indicatorContainer'] {
      @apply pl-0;
    }
  }

  .formSelector_v2 {
    width: 100%;
    min-width: 80px;
    [class*='-control'] {
      @apply h-12 w-full rounded-lg border border-solid border-gray-dark4;
      div {
        @apply flex h-12 items-center py-3  pr-0 text-sm font-medium leading-5.5;
        div {
          @apply h-auto py-0 leading-none;
        }
        & + div {
          @apply pr-2;
        }
      }
    }
    [data-value] {
      display: none !important;
    }
    [class*='-ValueContainer'] {
      @apply h-12 py-3  pr-0 text-sm font-medium leading-5.5 text-gray-50;
    }
    [class*='-Input'] {
      @apply m-0 hidden h-12 p-0;
      grid-area: unset;
    }
    [class*='-indicatorSeparator'] {
      @apply hidden;
    }
    [class*='-indicatorContainer'] {
      @apply pl-0;
    }
  }
}

.Toastify__toast {
  &-container.Toastify__toast-container {
    --toastify-toast-width: 21.875rem;
    @include mediaQuery(lg) {
      width: 100%;
      max-width: var(--toastify-toast-width);
      left: 50%;
      transform: translateX(-50%) translateY($hydra-spacing-24);
      .Toastify__toast {
        margin-bottom: $hydra-spacing-10;
      }
    }
  }
  &-body.Toastify__toast-body {
    @extend %flex-a-item-start;
  }
  &.Toastify__toast-theme--light {
    padding: $hydra-spacing-12;
    font-family: $bodyFont;
    border-radius: $hydra-radius-8;
    font-size: $hydra-text-sm;
    &.Toastify__toast--info {
      background-color: $primary600;
      border: $hydra-dim-01 solid $primary100;
      .Toastify__toast-icon {
        svg {
          color: $primary100;
        }
      }
    }
  }
  // &.Toastify__toast--success {
  //   background: $success;
  //   color: white !important;
  //   svg {
  //     path {
  //       fill: white;
  //     }
  //   }
  //   .Toastify__progress-bar--success {
  //     background: $darkGreen;
  //   }
  // }
  // &.Toastify__toast--info {
  //   background: #3f99d7 !important;
  //   color: white !important;
  //   border: none !important;
  //   svg {
  //     path {
  //       fill: white;
  //     }
  //   }
  //   .Toastify__progress-bar--info {
  //     background: #2073ab !important;
  //   }
  // }
  // &.Toastify__toast--error {
  //   background: #e74c3c;
  //   color: white !important;
  //   svg {
  //     path {
  //       fill: white;
  //     }
  //   }
  //   .Toastify__progress-bar--error {
  //     background: #a52c20;
  //   }
  // }
  .toastContent {
    font-size: $hydra-text-xs;
    color: $textColor8;
    font-weight: $hydra-font-regular;
    line-height: 1.5;
    b {
      font-weight: $hydra-font-semibold;
    }
  }
}
.tippy-box.tippy-box {
  background-color: #484848;
  border-radius: $hydra-radius-6;
  .tippy-content {
    background-color: #484848;
    font-size: $hydra-text-x;
    line-height: 1.2;
    color: $white;
    font-weight: $hydra-font-medium;
    padding: $hydra-spacing-12;
    border-radius: $hydra-radius-6;
  }
  .tippy-arrow {
    color: #484848;
  }
}
.hydra-dropdown {
  &__control.hydra-dropdown__control {
    border-color: $selectBoxBorder;
    min-height: 2.125rem;
    font-size: $hydra-text-xs;
    line-height: 1.5;
    color: $selectBoxInput;
    border-radius: $hydra-radius-6;
    cursor: pointer;
    [class*='mobilePanel'] & {
      font-size: $hydra-text-sm;
      @include mediaQuery(lg) {
        min-height: 3rem;
      }
    }
    &:hover {
      border-color: $primary;
      color: $textColor8;
    }
  }
  &__single-value {
    font-weight: $hydra-font-medium;
  }
  &__value-container {
  }
  &__placeholder {
  }
  &__input-container {
    font-weight: $hydra-font-medium;
  }
  &__indicators {
  }
  &__indicator-separator {
    display: none;
  }
  &__dropdown-indicator {
    svg {
      width: $hydra-dim-16;
      height: $hydra-dim-16;
    }
  }
  &__menu {
    font-size: $hydra-text-xs;
    line-height: 1.5;
    &-list {
    }
  }
  &__option.hydra-dropdown__option {
    background-color: $primary300;
    transition: $hydra-transition-speed-3 all;
    cursor: pointer;
    @apply flex items-center justify-between;
    &--is-focused.hydra-dropdown__option--is-focused {
      background-color: $primary200;
      color: $textColor8;
      &:active {
        background-color: $primary300;
      }
    }
    &--is-selected.hydra-dropdown__option--is-selected {
      background-color: $primary;
      color: $white;
    }
  }
}
.form-checkbox {
  position: relative;
  cursor: pointer;
  .form-checkbox-input {
    @extend %visuallyHidden;
    &:checked {
      ~ .form-check-circle {
        background-color: $primary100;
        svg {
          color: $white;
        }
      }
      &:disabled {
        ~ .form-check-circle {
          background-color: $gray800;
        }
      }
    }
    &:disabled {
      ~ .form-check-circle {
        cursor: not-allowed;
        border-color: $gray800;
      }
    }
  }
  .form-check-circle {
    width: $hydra-dim-18;
    height: $hydra-dim-18;
    border: $hydra-dim-01 solid $textColor9;
    border-radius: $hydra-radius-full;
    @extend %flex-a-j-center;
    background-color: transparent;
    transition: $hydra-transition-speed-3 all;
    cursor: pointer;
    @extend %flexShrink-0;
    svg {
      width: $hydra-dim-10;
      height: $hydra-dim-10;
      color: transparent;
      transition: $hydra-transition-speed-3 all;
    }
  }
  &.-regular-mobile {
    @extend %flex;
    @include column-gap($hydra-spacing-08);
    .form-check-circle {
      border-radius: $hydra-radius-4;
    }
    @include mediaQuery(lg) {
      min-height: 3.3125rem;
      width: 100%;
      padding: $hydra-spacing-16;
      border-radius: $hydra-radius-8;
      border: $hydra-dim-01 solid $gray500;
      background-color: $white;
      &:has(.form-checkbox-input:checked) {
        border-color: $primary100;
        background-color: $primary600;
        .label-text {
          font-weight: $hydra-font-medium;
        }
      }
      .form-check-circle {
        order: 2;
      }
      .label-text {
        order: 1;
      }
      .form-checkbox-input {
        &:checked {
          ~ .form-check-circle {
            border-radius: $hydra-radius-full;
            background-color: $white;
            border: $hydra-dim-05 solid $primary100;
          }
        }
        ~ .form-check-circle {
          border-radius: $hydra-radius-full;
          background-color: $white;
          margin-left: auto;
          width: $hydra-dim-18;
          height: $hydra-dim-18;
          svg {
            display: none;
          }
        }
      }
    }
  }
  &.-card-options {
    @extend %flex-a-item-center;
    @include column-gap($hydra-spacing-08);
    min-height: 3.3125rem;
    width: 100%;
    padding: $hydra-spacing-16;
    border-radius: $hydra-radius-8;
    border: $hydra-dim-01 solid $gray500;
    background-color: $white;
    &:has(.form-checkbox-input:checked) {
      border-color: $primary100;
      background-color: $primary600;
    }
    .form-check-circle {
      order: 2;
      @extend %flex;
    }
    .label-text {
      order: 1;
      @extend %flex;
      font-weight: $hydra-font-medium;
      font-size: $hydra-text-sm;
    }
    .form-checkbox-input {
      &:checked {
        ~ .form-check-circle {
          border-radius: $hydra-radius-full;
          background-color: $white;
          border: $hydra-dim-05 solid $primary100;
        }
      }
      ~ .form-check-circle {
        border-radius: $hydra-radius-full;
        background-color: $white;
        margin-left: auto;
        width: $hydra-dim-18;
        height: $hydra-dim-18;
        svg {
          display: none;
        }
      }
    }
  }
  .label-text {
    font-size: $hydra-text-xs;
    line-height: 1.5;
    font-weight: $hydra-font-regular;
    color: $textColor8;
  }
}
.form-checkbox {
  @apply relative cursor-pointer;
  input {
    @apply pointer-events-none absolute top-0 left-0 opacity-0;
    &:checked {
      & + .form-check-circle {
        @apply border-primary bg-primary;
      }
    }
  }
  .form-check-circle {
    @apply flex h-4 w-4 items-center justify-center rounded-full border border-gray-medium bg-white leading-6;
  }
}

.form-check-circle {
  @apply flex h-4.5 w-4.5 items-center justify-center rounded-full border border-gray-medium bg-white leading-6;
  svg {
    @apply h-4.5 w-4.5;
  }
}
.custom-horizontal-scrollbar {
  ::-webkit-scrollbar {
    @apply h-2 w-1;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
.custom-vertical-scrollbar {
  ::-webkit-scrollbar {
    @apply w-1;
  }
  &::-webkit-scrollbar {
    @apply w-1;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
body {
  ::-webkit-scrollbar {
    @apply h-2 w-1;
  }
  &::-webkit-scrollbar {
    @apply h-2 w-1;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.tox.tox.tox {
  &.tox-tinymce,
  &.tox-tinymce-aux {
    font-family: $bodyFont;
  }
  .tox-label {
    margin-bottom: $hydra-spacing-03;
  }
  .tox-listbox__select-label,
  .tox-dialog__title,
  .tox-button,
  .tox-label {
    font-family: $bodyFont;
  }
  &.tox-tinymce {
    border-color: $borderShade4;
    min-height: 14.875rem;
  }
}

.otpInput {
  input {
    @apply mr-4 flex h-12 w-12.5 flex-col items-center justify-center rounded-lg border border-solid border-gray-dark text-base font-semibold leading-4.5 text-black outline-none focus:border-primary;
    width: 3.125rem !important;
    height: 3rem !important;
    margin-right: 16px !important;
  }
}

.tableStyle {
  @apply rounded-lg border border-white200;
  .MuiToolbar-gutters {
    @apply hidden;
  }
  div {
    @apply bg-transparent shadow-none;
  }
  table {
    @apply w-full overflow-auto rounded-t-lg border border-white200 xl:overflow-auto xl:rounded-none xl:border-none;
    thead {
      @apply relative  bg-white300 text-xs font-medium leading-4 text-gray-medium xl:hidden;
      th {
        @apply border-b border-white200 bg-transparent py-2 px-4 text-left;

        div {
          @apply px-4 py-1 text-left text-xs font-medium leading-4 text-gray-medium;
        }
      }
    }
    tbody {
      @apply bg-white;
      td {
        @apply relative border-b border-white200  px-4 py-4 text-xs font-medium text-gray-dark;
        font-size: 12px !important;
        font-weight: 500 !important;
        color: #272727 !important;
        div {
          padding: 0;
        }
      }
      tr {
        @apply bg-white;
      }
    }
    .MuiTablePagination-toolbar {
      @apply border-none;
    }
  }
  .MuiTablePagination-root {
    border: none !important;
  }
  .verified {
    color: #298529 !important;
    @apply -ml-4 bg-no-repeat pl-8 text-xs font-medium capitalize leading-4.5 text-green-dark;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M9.28033 0.21967C9.57322 0.512563 9.57322 0.987437 9.28033 1.28033L3.78033 6.78033C3.48744 7.07322 3.01256 7.07322 2.71967 6.78033L0.21967 4.28033C-0.0732233 3.98744 -0.0732233 3.51256 0.21967 3.21967C0.512563 2.92678 0.987437 2.92678 1.28033 3.21967L3.25 5.18934L8.21967 0.21967C8.51256 -0.0732233 8.98744 -0.0732233 9.28033 0.21967Z' fill='%23298529'/%3e%3c/svg%3e ");
    background-position: 16px center;
  }
  .not_verified {
    color: #a07808 !important;

    @apply -ml-4 bg-no-repeat pl-8 text-xs font-medium capitalize leading-4.5 text-yellow-dark;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M5.99999 1.60002C3.56994 1.60002 1.59999 3.56997 1.59999 6.00002C1.59999 8.43008 3.56994 10.4 5.99999 10.4C8.43005 10.4 10.4 8.43008 10.4 6.00002C10.4 3.56997 8.43005 1.60002 5.99999 1.60002ZM0.399994 6.00002C0.399994 2.90723 2.9072 0.400024 5.99999 0.400024C9.09279 0.400024 11.6 2.90723 11.6 6.00002C11.6 9.09282 9.09279 11.6 5.99999 11.6C2.9072 11.6 0.399994 9.09282 0.399994 6.00002Z' fill='%23A07808'/%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M5.99999 2.40002C6.33136 2.40002 6.59999 2.66865 6.59999 3.00002V5.6292L8.26832 6.46337C8.56471 6.61156 8.68484 6.97197 8.53665 7.26835C8.38846 7.56474 8.02805 7.68487 7.73167 7.53668L5.73167 6.53668C5.5284 6.43505 5.39999 6.22729 5.39999 6.00002V3.00002C5.39999 2.66865 5.66862 2.40002 5.99999 2.40002Z' fill='%23A07808'/%3e%3c/svg%3e ");
    background-position: 16px center;
  }
  .remove_billing {
    color: #c03c3e !important;

    @apply -ml-4 bg-no-repeat pl-8 text-xs font-medium capitalize leading-4.5 text-yellow-dark;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_4532_6512)'%3E%3Cpath d='M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z' stroke='%23c03c3e' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M6 3V6L8 7' stroke='%23c03c3e' stroke-width='1.2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_4532_6512'%3E%3Crect width='12' height='12' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    background-position: 16px center;
  }

  .MuiTablePagination-toolbar {
    @apply flex rounded-b-lg border border-t-0 border-white200 bg-white  pl-4 xl:overflow-auto xl:rounded-none xl:border-none;
    tfoot {
      @apply bg-white;
      [class*='-select'] {
        margin-right: auto;
      }
      [class*='-selectLabel'] {
        margin-right: 0;
      }
    }
    [class*='-spacer'] {
      display: none;
    }
    [class*='-select'] {
      margin-right: auto;
    }
    [class*='-selectLabel'] {
      margin-right: 0;
    }
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.spinner {
  width: 70px;
  text-align: center;
  position: absolute;
  //top: 50%;
  //left: 50%;
  // transform: translate(-50%, -50%);
  > div {
    width: 18px;
    height: 18px;
    background-color: #213c92;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}
.add-user-admin {
  .form-checkbox-input {
    pointer-events: auto;
    right: 0;
    z-index: 9;
    height: 100%;
    cursor: pointer;
  }
}
.edit-icon {
  color: #4765e5;
  svg {
    margin-right: 5px;
    cursor: pointer;
  }
}
.link-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.button-disabled {
  opacity: 0.5;
}
.profile-loader {
  .fixed {
    margin-top: 104px;
    height: calc(100% - 104px);
  }
}
.hydra___dropdown {
  @apply truncate;
}
.hydra___dropdown__option {
  display: flex !important;
  align-items: center !important;
  word-break: break-all;
  @apply truncate;
  button {
    margin-left: 20px;
  }
  span {
    @apply block truncate;
  }
  div {
    @apply relative mr-auto ml-2 block truncate pl-2 text-sm italic text-gray-600;
    &:after {
      content: '';
      @apply absolute left-0 top-2 h-1 w-1 rounded-full bg-gray-600;
    }
  }
}
.hydra___dropdown__option--is-selected {
  div {
    @apply text-white;
    &:after {
      @apply bg-white;
    }
  }
  span {
    @apply text-white;
  }
  button {
    @apply bg-white;
  }
}
.hydra___dropdown__control {
  min-height: 48px !important;
}
.channelTickIcon {
  @apply text-green-600;
  svg {
    width: 17px;
    //margin-bottom: -1px;
    height: 17px;
  }
}
.user-details-grid {
  .react-tel-input {
    @apply border border-solid border-gray-dark4;
  }
}
.react-tel-input {
  height: 48px;
  @apply rounded-lg border border-solid border-gray-dark4  text-sm font-medium leading-[1.375rem] text-gray-dark;

  .form-control {
    height: 46px;
    @apply border-none bg-transparent pl-10;
  }
  .flag-dropdown {
    @apply border-none bg-transparent;
    background: transparent !important;
    .selected-flag {
      background: transparent !important;
    }
  }
  .country-list .country {
    text-align: left;
  }
  .country-list .search-box {
    height: 40px;
    width: 88%;
    margin-left: 6px;
  }
  .search {
    @apply flex items-center;
  }
}
input[type='date'] {
  &::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
  &::-moz-calendar-picker-indicator {
    cursor: pointer;
  }
}
.tableFilter {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.12);
  background: #fcfaf6;
  @apply -mx-5 -mt-5 mb-5 md:mt-0;
}

.verified .react-tel-input {
  height: 37px !important;
  margin-top: 5.5px;
  input {
    height: 36px !important;
  }
}

.faq-table {
  border: 1px solid rgba(0, 0, 0, 0.25) !important ;
  width: 100% !important;

  td {
    border: 1px solid rgba(0, 0, 0, 0.25) !important ;
    padding: 10px;
    min-width: 100px;
  }
}

.table-dropdown-content {
  animation: growDown 0.3s ease-in-out forwards;
  transform-origin: top center;
  top: calc(100% + 0.5rem);
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
      opacity: 0.65;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
  position: absolute;
  right: 0;
  z-index: 5;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.25);
  border-radius: 0.25rem;
  background-color: #ffffff;
  min-width: 105px;
  max-width: 105px;
  opacity: 0;
}
.delete-later {
  @extend %flex;
  @include column-gap($hydra-spacing-08);
  .form-check-circle {
    border-radius: $hydra-radius-4;
  }
}
.user-management-icon {
  svg {
    width: 28px;
    height: 28px;
    fill: #1d337c;
    margin-bottom: -4px;
    fill-rule: evenodd;
  }
}
.user-license-icon {
  svg {
    fill: #0e6231;
  }
}
.user-license-expiry-icon {
  svg {
    fill: #9a7c24;
  }
}
.user-management-cards-section {
  .license-card {
    overflow: hidden;
    &:hover {
      .contents-section {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
  .contents-section {
    display: grid;
    grid-template-rows: 0fr;
    position: relative;
    transition: grid-template-rows 500ms;
    opacity: 0;
    @media screen and (min-width: 1024px) {
      min-width: 170px;
    }
  }
  .contents-section {
    grid-template-rows: 1fr;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(33, 59, 146, 0.936);
    padding: 16px;
    color: white;

    transition: all ease-in-out 0.3s;
    transform: translateY(100%);
  }
}
.user-management-card-main {
  @media screen and (min-width: 992px) {
    align-items: start;
    .user-management-buttons {
      margin-top: 20px;
    }
  }
}
